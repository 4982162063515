<template>

    <Context v-if="block.advanced?.context && __blockMap[type]"
        :context="block.advanced?.context">
        <component :is="__blockMap[type]" v-bind="block" />
    </Context>

    <component v-else-if="__blockMap[type]" :is="__blockMap[type]"
        v-bind="block" />

    <div v-else>
        <h2>Block not found</h2>
        <pre>{{ block }}</pre>
    </div>

</template>
<script setup lang="ts">
import __blockMap from './block.map.js';
import type { TBlock } from './block.type';
import Context from '~/components/context/context.vue';

const props = withDefaults(defineProps<TBlock>(), {
});

const type = props.block.__typename ?? props.type;


</script>