<template>
  <Blocks :key="seed" :blocks="page?.blocks ?? page?.content ?? []" />
</template>

<script setup lang="ts">
import Blocks from '~/components/blocks/blocks.vue';
import { toHead } from 'vue-datocms';
import { withDefaults } from 'vue';
import { type TPage } from './page.type';

const props = withDefaults(defineProps<{
  page: TPage 
}>(), {
});

// preparing the metas to be added to the head
const metas: any[] = props.page?._seoMetaTags || [];

// adding alternate pages based on the current page
// and the current locale
if (props.page?._allSlugLocales?.length) {
  for (let [i, alternatePage] of props.page._allSlugLocales.entries()) {
    metas.push({
      tag: 'link',
      attributes: {
        rel: 'alternate',
        hreflang: alternatePage.locale,
        href: `/${alternatePage.locale}/${alternatePage.value}`,
      },
    });
  }
}

// update seed on update event
const seed = ref(0);
if (import.meta.client) {
  document.body.addEventListener('update', () => {
    document.body.classList.add('-reduce-motion');
    seed.value++;
    setTimeout(() => {
      document.body.classList.remove('-reduce-motion');
    }, 100);
  });
}

// inject metas into head
useHead(() => {
  return toHead(metas);
});
</script>
